@import './prismjs';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  background-color: #000;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

.grecaptcha-badge {
  display: none !important;
}

.MuiSelect-selectMenu {
  color: black !important;
}

.label-disabled {
  label {
    color: rgba(0, 0, 0, 0.26);
  }
  .MuiSelect-selectMenu {
    color: rgba(0, 0, 0, 0.26);
  }
}

.dropdown {
  .MuiFormLabel-root {
    background: #f4f5f8;
    padding: 0 5px;
    margin: 0 -5px;
  }
}
